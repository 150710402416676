import React, { useState } from "react"
import { Link } from "gatsby"

import { Tabs } from 'antd';

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/ContactForm"


//Medias
import bagues_001 from "../images/images/bagues/bagues_001.png"


import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

//Images
import Yvan_monnet_001 from "../images/images/yvan_monnet/Five_Detail-01.jpg"

import Yvan_monnet_002 from "../images/images/yvan_monnet/2101_Montage_Blanc.jpg"
import Yvan_monnet_003 from "../images/images/yvan_monnet/2101_Montage_Bleu.jpg"
import Yvan_monnet_004 from "../images/images/yvan_monnet/2301_Cacao_Satin_Montage.jpg"
import Yvan_monnet_005 from "../images/images/yvan_monnet/Five_Black-01-1-comp.jpg"
import Yvan_monnet_006 from "../images/images/yvan_monnet/Five_Profil-02-Comp.jpg"
import Yvan_monnet_007 from "../images/images/yvan_monnet/Five_Sous-01_Web.jpg"
import Yvan_monnet_008 from "../images/images/yvan_monnet/2301_Blanc_VNoir.png"
import Yvan_monnet_009 from "../images/images/yvan_monnet/2301_Bleu_Lezard.png"
import Yvan_monnet_010 from "../images/images/yvan_monnet/2301-Cacao_3D.png"
import Yvan_monnet_011 from "../images/images/yvan_monnet/Blanc_3d.png"
import Yvan_monnet_012 from "../images/images/yvan_monnet/Bleu_3d.png"


import Grovana_m_prez from "../images/images/grovana/Grovana_prez.jpg"

import Grovana_m_001 from "../images/images/grovana/men/grovana_m_001.jpg"
import Grovana_m_002 from "../images/images/grovana/men/grovana_m_002.jpg"
import Grovana_m_003 from "../images/images/grovana/men/grovana_m_003.jpg"
import Grovana_m_004 from "../images/images/grovana/men/grovana_m_004.jpg"
import Grovana_m_005 from "../images/images/grovana/men/grovana_m_005.jpg"
import Grovana_m_006 from "../images/images/grovana/men/grovana_m_006.jpg"
import Grovana_m_007 from "../images/images/grovana/men/grovana_m_007.jpg"
import Grovana_m_008 from "../images/images/grovana/men/grovana_m_008.jpg"
import Grovana_m_009 from "../images/images/grovana/men/grovana_m_009.jpg"
import Grovana_m_010 from "../images/images/grovana/men/grovana_m_010.jpg"
import Grovana_m_011 from "../images/images/grovana/men/grovana_m_011.jpg"
import Grovana_m_012 from "../images/images/grovana/men/grovana_m_012.jpg"
import Grovana_m_013 from "../images/images/grovana/men/grovana_m_013.jpg"
import Grovana_m_014 from "../images/images/grovana/men/grovana_m_014.jpg"
import Grovana_m_015 from "../images/images/grovana/men/grovana_m_015.jpg"
import Grovana_m_016 from "../images/images/grovana/men/grovana_m_016.jpg"
import Grovana_m_017 from "../images/images/grovana/men/grovana_m_017.jpg"
import Grovana_m_018 from "../images/images/grovana/men/grovana_m_018.jpg"
import Grovana_m_019 from "../images/images/grovana/men/grovana_m_019.jpg"
import Grovana_m_020 from "../images/images/grovana/men/grovana_m_020.jpg"
import Grovana_m_021 from "../images/images/grovana/men/grovana_m_021.jpg"
import Grovana_m_022 from "../images/images/grovana/men/grovana_m_022.jpg"
import Grovana_m_023 from "../images/images/grovana/men/grovana_m_023.jpg"

import Grovana_w_001 from "../images/images/grovana/women/grovana_w_001.jpg"
import Grovana_w_002 from "../images/images/grovana/women/grovana_w_002.jpg"
import Grovana_w_003 from "../images/images/grovana/women/grovana_w_003.jpg"
import Grovana_w_004 from "../images/images/grovana/women/grovana_w_004.jpg"
import Grovana_w_005 from "../images/images/grovana/women/grovana_w_005.jpg"
import Grovana_w_006 from "../images/images/grovana/women/grovana_w_006.jpg"
import Grovana_w_007 from "../images/images/grovana/women/grovana_w_007.jpg"
import Grovana_w_008 from "../images/images/grovana/women/grovana_w_008.jpg"
import Grovana_w_009 from "../images/images/grovana/women/grovana_w_009.jpg"
import Grovana_w_010 from "../images/images/grovana/women/grovana_w_010.jpg"
import Grovana_w_011 from "../images/images/grovana/women/grovana_w_011.jpg"
import Grovana_w_012 from "../images/images/grovana/women/grovana_w_012.jpg"
import Grovana_w_013 from "../images/images/grovana/women/grovana_w_013.jpg"
import Grovana_w_014 from "../images/images/grovana/women/grovana_w_014.jpg"
import Grovana_w_015 from "../images/images/grovana/women/grovana_w_015.jpg"
import Grovana_w_016 from "../images/images/grovana/women/grovana_w_016.jpg"
import Grovana_w_017 from "../images/images/grovana/women/grovana_w_017.jpg"


import Maserati_prez from "../images/images/maserati/maserati-damenuhren.jpg"

import Maserati_m_001 from "../images/images/maserati/men/maserati_m_001.jpg"
import Maserati_m_002 from "../images/images/maserati/men/maserati_m_002.jpg"
import Maserati_m_003 from "../images/images/maserati/men/maserati_m_003.jpg"
import Maserati_m_004 from "../images/images/maserati/men/maserati_m_004.jpg"
import Maserati_m_005 from "../images/images/maserati/men/maserati_m_005.jpg"
import Maserati_m_006 from "../images/images/maserati/men/maserati_m_006.jpg"
import Maserati_m_007 from "../images/images/maserati/men/maserati_m_007.jpg"
import Maserati_m_008 from "../images/images/maserati/men/maserati_m_008.jpg"
import Maserati_m_009 from "../images/images/maserati/men/maserati_m_009.jpg"
import Maserati_m_010 from "../images/images/maserati/men/maserati_m_010.jpg"
import Maserati_m_011 from "../images/images/maserati/men/maserati_m_011.jpg"
import Maserati_m_012 from "../images/images/maserati/men/maserati_m_012.jpg"
import Maserati_m_013 from "../images/images/maserati/men/maserati_m_013.jpg"
import Maserati_m_014 from "../images/images/maserati/men/maserati_m_014.jpg"
import Maserati_m_015 from "../images/images/maserati/men/maserati_m_015.jpg"
import Maserati_m_016 from "../images/images/maserati/men/maserati_m_016.jpg"
import Maserati_m_017 from "../images/images/maserati/men/maserati_m_017.jpg"
import Maserati_m_018 from "../images/images/maserati/men/maserati_m_018.jpg"
import Maserati_m_019 from "../images/images/maserati/men/maserati_m_019.jpg"
import Maserati_m_020 from "../images/images/maserati/men/maserati_m_020.jpg"
import Maserati_m_021 from "../images/images/maserati/men/maserati_m_021.jpg"
import Maserati_m_022 from "../images/images/maserati/men/maserati_m_022.jpg"
import Maserati_m_023 from "../images/images/maserati/men/maserati_m_023.jpg"
import Maserati_m_024 from "../images/images/maserati/men/maserati_m_024.jpg"
import Maserati_m_025 from "../images/images/maserati/men/maserati_m_025.jpg"
import Maserati_m_026 from "../images/images/maserati/men/maserati_m_026.jpg"
import Maserati_m_027 from "../images/images/maserati/men/maserati_m_027.jpg"
import Maserati_m_028 from "../images/images/maserati/men/maserati_m_028.jpg"
import Maserati_m_029 from "../images/images/maserati/men/maserati_m_029.jpg"
import Maserati_m_030 from "../images/images/maserati/men/maserati_m_030.jpg"
import Maserati_m_031 from "../images/images/maserati/men/maserati_m_031.jpg"
import Maserati_m_032 from "../images/images/maserati/men/maserati_m_032.jpg"

import Maserati_w_001 from "../images/images/maserati/women/maserati_w_001.jpg"
import Maserati_w_002 from "../images/images/maserati/women/maserati_w_002.jpg"
import Maserati_w_003 from "../images/images/maserati/women/maserati_w_003.jpg"
import Maserati_w_004 from "../images/images/maserati/women/maserati_w_004.jpg"
import Maserati_w_005 from "../images/images/maserati/women/maserati_w_005.jpg"
import Maserati_w_006 from "../images/images/maserati/women/maserati_w_006.jpg"
import Maserati_w_007 from "../images/images/maserati/women/maserati_w_007.jpg"
import Maserati_w_008 from "../images/images/maserati/women/maserati_w_008.jpg"
import Maserati_w_009 from "../images/images/maserati/women/maserati_w_009.jpg"
import Maserati_w_010 from "../images/images/maserati/women/maserati_w_010.jpg"
import Maserati_w_011 from "../images/images/maserati/women/maserati_w_011.jpg"
import Maserati_w_012 from "../images/images/maserati/women/maserati_w_012.jpg"
import Maserati_w_013 from "../images/images/maserati/women/maserati_w_013.jpg"
import Maserati_w_014 from "../images/images/maserati/women/maserati_w_014.jpg"
import Maserati_w_015 from "../images/images/maserati/women/maserati_w_015.jpg"
import Maserati_w_016 from "../images/images/maserati/women/maserati_w_016.jpg"


import Masterblock_001 from "../images/images/masterblock/MasterBlock_3623_Face_fd_noir_sans_reflet.jpg"

import Masterblock_002 from "../images/images/masterblock/Corr_Dos_PR_variante_2_fd_noir.jpg"
import Masterblock_003 from "../images/images/masterblock/Corr_Dos_tech variante_1_fd_noir.jpg"
import Masterblock_004 from "../images/images/masterblock/Corr_Face_tech_fd_noir.jpg"
import Masterblock_005 from "../images/images/masterblock/Corr_PR_recto_verso_variante_1_fd_noir.jpg"
import Masterblock_006 from "../images/images/masterblock/MASERBLOCK_1.jpg"
import Masterblock_007 from "../images/images/masterblock/MASTERBLOCK_2.jpg"
import Masterblock_008 from "../images/images/masterblock/MASTERBLOCK_4.jpg"
import Masterblock_009 from "../images/images/masterblock/MASTERBLOCK_3.jpg"
import Masterblock_010 from "../images/images/masterblock/MasterBlock_3623_Dos_fd_noir_sans_reflet.jpg"
import Masterblock_011 from "../images/images/masterblock/Mouvement_fd_noir.jpg"


import Remontroirs_001 from "../images/images/remontroirs_montres_automatiques/remontroirs_001.jpg"
import Remontroirs_002 from "../images/images/remontroirs_montres_automatiques/remontroirs_002.jpg"
import Remontroirs_003 from "../images/images/remontroirs_montres_automatiques/remontroirs_003.jpg"
import Remontroirs_004 from "../images/images/remontroirs_montres_automatiques/remontroirs_004.jpg"
import Remontroirs_005 from "../images/images/remontroirs_montres_automatiques/rotore_one_sport_cyclamen_resized.jpg"
import Remontroirs_006 from "../images/images/remontroirs_montres_automatiques/rotore_one_sport_lilac_resized.jpg"
import Remontroirs_007 from "../images/images/remontroirs_montres_automatiques/rotor-one_blue_batman.jpg"
import Remontroirs_008 from "../images/images/remontroirs_montres_automatiques/rotor-one_red_pepsi.jpg"
import Remontroirs_009 from "../images/images/remontroirs_montres_automatiques/191101_scatola_boitenoire_ouvert.jpg"
import Remontroirs_010 from "../images/images/remontroirs_montres_automatiques/191101_scatola_boitegrise_ouvert.jpg"
import Remontroirs_011 from "../images/images/remontroirs_montres_automatiques/valigetta_4_choco.jpg"
import Remontroirs_012 from "../images/images/remontroirs_montres_automatiques/valigetta_4_bicolor.jpg"
import Remontroirs_013 from "../images/images/remontroirs_montres_automatiques/viaggio_1_choco.jpg"
import Remontroirs_014 from "../images/images/remontroirs_montres_automatiques/viaggio_1_black.jpg"
import Remontroirs_015 from "../images/images/remontroirs_montres_automatiques/viaggio_1_chestnut.jpg"
import Remontroirs_016 from "../images/images/remontroirs_montres_automatiques/viaggio_6_black.jpg"
import Remontroirs_017 from "../images/images/remontroirs_montres_automatiques/tesoro_chestnut_2_1_3.jpg"
import Remontroirs_018 from "../images/images/remontroirs_montres_automatiques/7rt_black_2.jpg"
import Remontroirs_019 from "../images/images/remontroirs_montres_automatiques/7rt_chestnut_2.jpg"
import Remontroirs_020 from "../images/images/remontroirs_montres_automatiques/valigetta_8_chestnut.jpg"
import Remontroirs_021 from "../images/images/remontroirs_montres_automatiques/valigetta_8_choco.jpg"
import Remontroirs_022 from "../images/images/remontroirs_montres_automatiques/valigetta_8_handle_black.jpg"
import Remontroirs_023 from "../images/images/remontroirs_montres_automatiques/valigetta_8_black.jpg"
import Remontroirs_024 from "../images/images/remontroirs_montres_automatiques/valigetta_16_chestnut.jpg"
import Remontroirs_025 from "../images/images/remontroirs_montres_automatiques/191101_scatola_pochegrise_montres.jpg"
import Remontroirs_026 from "../images/images/remontroirs_montres_automatiques/191101_scatola_pochenoire_montres.jpg"
import Remontroirs_027 from "../images/images/remontroirs_montres_automatiques/cornice_3_black.jpg"
import Remontroirs_028 from "../images/images/remontroirs_montres_automatiques/timesafe_xxl_open_resized.jpg"
import Remontroirs_029 from "../images/images/remontroirs_montres_automatiques/Origin7-500x500.jpg"


import Evertest_001 from "../images/images/bracelets_evertest/Evertest_001.jpg"

import Evertest_002 from "../images/images/bracelets_evertest/Evertest_002.jpg"
import Evertest_003 from "../images/images/bracelets_evertest/Evertest_003.jpg"
import Evertest_004 from "../images/images/bracelets_evertest/Evertest_004.jpg"
import Evertest_005 from "../images/images/bracelets_evertest/Evertest_005.jpg"
import Evertest_006 from "../images/images/bracelets_evertest/Evertest_006.jpg"
import Evertest_007 from "../images/images/bracelets_evertest/Evertest_007.jpg"
import Evertest_008 from "../images/images/bracelets_evertest/Evertest_008.jpg"
import Evertest_009 from "../images/images/bracelets_evertest/Evertest_009.jpg"

import swisskubik_001 from "../images/images/swisskubik/swisskubik_001.jpg"
import swisskubik_002 from "../images/images/swisskubik/swisskubik_002.jpg"
import swisskubik_003 from "../images/images/swisskubik/swisskubik_003.png"
import swisskubik_004 from "../images/images/swisskubik/swisskubik_004.png"
import swisskubik_005 from "../images/images/swisskubik/swisskubik_005.png"
import swisskubik_006 from "../images/images/swisskubik/swisskubik_006.png"
import swisskubik_007 from "../images/images/swisskubik/swisskubik_007.png"
import swisskubik_008 from "../images/images/swisskubik/swisskubik_008.png"
import swisskubik_009 from "../images/images/swisskubik/swisskubik_009.png"
import swisskubik_010 from "../images/images/swisskubik/swisskubik_010.png"
import swisskubik_011 from "../images/images/swisskubik/swisskubik_011.png"
import swisskubik_012 from "../images/images/swisskubik/swisskubik_012.png"
import swisskubik_013 from "../images/images/swisskubik/swisskubik_013.png"
import swisskubik_014 from "../images/images/swisskubik/swisskubik_014.png"
import swisskubik_015 from "../images/images/swisskubik/swisskubik_015.png"
import swisskubik_016 from "../images/images/swisskubik/swisskubik_016.png"
import swisskubik_017 from "../images/images/swisskubik/swisskubik_017.png"
import swisskubik_018 from "../images/images/swisskubik/swisskubik_018.png"
import swisskubik_019 from "../images/images/swisskubik/swisskubik_019.png"
import swisskubik_020 from "../images/images/swisskubik/swisskubik_020.png"
import swisskubik_021 from "../images/images/swisskubik/swisskubik_021.png"
import swisskubik_022 from "../images/images/swisskubik/swisskubik_022.png"
import swisskubik_023 from "../images/images/swisskubik/swisskubik_023.png"
import { Button, Input, Modal, Tag } from "antd"


const options = {
  overlayColor: "rgba(44,47,76,0.9)",
  captionStyle: {
    captionColor: "#857e74",
    captionFontFamily: "Raleway, sans-serif",
    captionFontSize: "22px",
    captionFontWeight: "300",
    captionFontStyle: "capitalize",
  },
  buttonsStyle: {
    buttonsBackgroundColor: "#857e74",
    buttonsIconColor: "rgba(176,158,111,0.8)",
  },
  autoplaySpeed: 1500,
  transitionSpeed: 0,
  showCaption: false,
  showThumbnails: false,
}

const Horlogeries = () => {

  const { TabPane } = Tabs;

  //Hooks
  const [dataContact, setDataContact] = useState(false)

  //Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

return (
  <>
  <Layout>
    <SEO title="Montres & Accessoires représentée dans nos boutiques"/>

    <div className="slideshow">
      <div className="usp">
        <div className="usp-txt-header">Montres & Accessoires</div>
        <div className="usp-txt">Montres & Accessoires représentées dans nos boutiques</div>
      </div>
    </div>
    <div className="border-gold-bottom"></div>


    <h3 style={{ fontSize: "6em" }}>Montres</h3>

    <div className="separator"></div>
    <span id="yvanmonnet" style={{ position: "absolute", marginTop: "-200px" }}></span>
    <section>
      <div className="section-flex-01" style={{ margin: "0px" }}>

        <img className="img" style={{ margin: "0px", marginTop: "6%", borderRadius: "12px" }} src={Yvan_monnet_001}
             alt="Collier Pendentif"/>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3 style={{ fontSize: '7em' }}>Yvan Monnet</h3>
          <p className="p-2 p-font ">
            Cet artisan créateur est issu de plusieurs décennies de maîtrise au sein de deux des plus prestigieuses
            manufactures horlogères genevoises dans lesquelles il a découvert les subtilités de la conception de la
            boîte de montre.
            Initié par des maîtres horlogers et des artisans d’Art au savoir-faire séculaire, il a activement participé
            à la conception et à la réalisation de modèles d’exception qui ont fait le bonheur d’amateurs avertis.
          </p>
          <h5 style={{color: '#333'}}>Une montre à hauteur d’Homme.</h5>
          <ul className="ul">
            <li className="li">Une réinterprétation du pentagone dans une version tout terrain, aussi élégante que sportive.</li>
            <li className="li">Étanche jusqu’à 200 mètres.</li>
            <li className="li">Mouvement mécanique à remontage automatique.</li>
            <li className="li">Fond saphir transparent.</li>
            <li className="li">Couronne Vissée</li>
            <li className="li">Super-Luminova 3D bleu.</li>
          </ul>

          <h5 style={{color: '#333'}}>En toute simplicité</h5>

          <p className="p-2 p-font">La boîte terminée à la main est réalisée par des artisans confirmés de Genève, fournisseurs de marques de prestige. La lunette de forme est construite sur cinq courbes tendues qui se croisent, avec des angles nets et une finition poli satiné. La glace, est assortie d’un joint d’étanchéité invisible logé dans la lunette. Le fond, qui reprend la forme de la lunette, est transparent et dévoile les belles finitions du mouvement mécanique. La couronne, vissée pour une étanchéité à 200m, reprend également la géométrie à cinq côtés du pentagone.La sophistication est visible aussi de nuit grâce à des appliques en Super-LumiNova 3D: de jour ce sont des rectangles, la nuit ce sont des pentagones qui s’illuminent.</p>

          <p className="p-2 p-font">
            La conception de la boîte à la géométrie disruptive aurait difficilement pu être développée sans une grande maîtrise des outils actuel de Conception Assistée par Ordinateur.
            Un usinage nécessitant agilité et expérience pour produire des boîtes de qualités en petites quantités et aux meilleurs coûts.
            Un geste parfait, un œil affûté pour réaliser une terminaison irréprochable qui magnifie l’harmonie du design.
          </p>

          <h5 style={{color: '#333'}}>Mina</h5>

          <p className="p-2 p-font">
            Sa forme, immédiatement reconnaissable, allie originalité et sobriété avec une élégance rare. Les couleurs des cadrans répondent à une envie de s’affirmer et de s’émanciper des codes actuels de notre société. Le laqué bleu plaira aux femmes en quête d’originalité et de dynamisme. Le laqué blanc est plus tendre en apparence mais a l’avantage de s’adapter aux tenues en leur apportant une touche de fraîcheur. Le satiné cacao jongle avec l’astre solaire ; Il offre des reflets jouant avec le regard et une brillance unique. Les mouvements décorés sont visibles à travers un verre saphir monté sur le fond. Ils sauront séduire les femmes qui apprécient voir le battement du cœur du mécanisme, apportant une touche de douceur et de sensualité.
          </p>

          <ul className="ul">
            <li className="li">La première montre bracelet pentagonale</li>
            <li className="li">Mouvement Suisse à remontage manuel ou automatique.</li>
          </ul>

          <p className="p-2 p-font">
            A l’heure actuelle, au sein du grand public, rares sont ceux qui parviennent à déceler les infinités de subtilités dont Mina fait l’objet, comme d’ailleurs toutes les montres qui se réclament du même segment, mais dont la valeur est souvent multipliée par deux ou plus.
            De plus, elle est titulaire d’une originalité qui la rend immédiatement reconnaissable. Sa forme pentagonale, aux esthétiques si savamment polies qu’elles fusionnent avec de parfaits arrondis, incarne ce dont tant de marques rêvent de pouvoir disposer : une touche identitaire marquée, unique et à nulle autre pareille.
          </p>

          <h5 style={{color: '#333'}}>Une Production Locale</h5>
          <p className="p-2 p-font">
            Les montres YvanMonneT sont produites à plus de 80% à Genève par des entreprises au savoir-faire reconnu dans la production de composants horloger haut de gamme.
          </p>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <div id="gallery-with-links" className="container content content-gallery">
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_002} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_002}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_003} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_003}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_004} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_004}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_005} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_005}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_006} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_006}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_007} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_007}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_001} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_001}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_008} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_008}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_009} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_009}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_010} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_010}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_011} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_011}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Yvan_monnet_012} data-attribute="SRL"><img className="img-gallery" src={Yvan_monnet_012}
                                                                      alt="Yvan Monnet"/></a>
                </div>
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
          <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Yvan Monnet') }>Nous contacter</Button></div>
        </div>

      </div>
    </section>


    <div className="separator"></div>
    <span id="grovana" style={{ position: "absolute", marginTop: "-200px" }}></span>
    <section>

      <div className="section-flex-01" style={{ margin: "0px" }}>

        <img className="img" style={{ margin: "0px", marginTop: "6%", borderRadius: "12px" }} src={Grovana_m_prez}
             alt="Collier Pendentif"/>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3 style={{ fontSize: '7em' }}>Grovana</h3>
          <p className="p-2 p-font">
            Créée en 1924 et basée à Bâle (Suisse), Grovana est une entreprise familiale.
            Cette marque horlogères produit (depuis plus de 90 ans) des montres de haute qualité avec des prix très
            attractifs.
            Tous leurs modèles sont garantis « swissmade » et sont munis de verres saphirs.
            La satisfaction de leurs clients est une priorité.
            Ils ont un service après-vente très réactif.
            Montres sportives, classiques et élégantes. Vous trouverez sur notre site quelques exemples de leurs
            montres.
          </p>
          <SimpleReactLightbox>
            <div className="h4-2">Homme</div>
            <SRLWrapper options={options}>
              <div id="gallery-with-links" className="container content content-gallery">
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_001} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_001}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_002} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_002}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_003} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_003}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_004} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_004}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_005} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_005}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_006} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_006}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_007} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_007}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_008} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_008}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_009} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_009}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_010} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_010}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_011} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_011}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_012} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_012}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_013} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_013}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_014} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_014}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_015} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_015}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_016} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_016}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_017} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_017}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_018} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_018}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_019} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_019}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_020} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_020}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_021} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_021}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_022} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_022}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_m_023} data-attribute="SRL"><img className="img-gallery" src={Grovana_m_023}
                                                                    alt="Grovana"/></a>
                </div>
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
          <SimpleReactLightbox>
            <div className="h4-2">Femme</div>
            <SRLWrapper options={options}>
              <div id="gallery-with-links" className="container content content-gallery">
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_001} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_001}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_002} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_002}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_003} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_003}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_004} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_004}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_005} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_005}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_006} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_006}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_007} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_007}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_008} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_008}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_009} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_009}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_010} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_010}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_011} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_011}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_012} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_012}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_013} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_013}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_014} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_014}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_015} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_015}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_016} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_016}
                                                                    alt="Grovana"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Grovana_w_017} data-attribute="SRL"><img className="img-gallery" src={Grovana_w_017}
                                                                    alt="Grovana"/></a>
                </div>
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
          <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Grovana') }>Nous contacter</Button></div>
        </div>

      </div>
    </section>


    <div className="separator"></div>
    <span id="maserati" style={{ position: "absolute", marginTop: "-200px" }}></span>
    <section>

      <div className="section-flex-01" style={{ margin: "0px" }}>

        <img className="img" style={{ margin: "0px", marginTop: "6%", borderRadius: "12px" }} src={Maserati_prez}
             alt="Montres Maserati"/>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3 style={{ fontSize: '7em' }}>Maserati</h3>
          <p  className="p-2 p-font" style={{paddingBottom: 0, marginBottom: 0}}>
            La perfection technique et l'attention apportée aux détails par Maserati pour chacune de ses voitures se reflètent dans des chronographes au style incomparable, qui reprennent sous la forme de créations originales et élégantes les éléments les plus emblématiques de l'histoire et du design de la marque au Trident.
          </p>

          <p  className="p-2 p-font" style={{paddingBottom: 0, marginBottom: 0}}>Un grand choix de montres pour tous les styles.</p>
          <p  className="p-2 p-font" style={{paddingTop: 0, marginTop: 0}}>Retrouvez la dernière collection dans nos boutiques.</p>

          <SimpleReactLightbox>
            <div className="h4-2">Homme</div>
            <SRLWrapper options={options}>
              <div id="gallery-with-links" className="container content content-gallery">
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_001} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_001}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_002} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_002}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_003} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_003}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_004} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_004}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_005} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_005}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_006} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_006}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_007} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_007}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_008} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_008}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_009} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_009}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_010} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_010}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_011} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_011}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_012} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_012}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_013} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_013}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_014} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_014}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_015} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_015}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_016} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_016}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_017} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_017}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_018} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_018}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_019} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_019}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_020} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_020}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_021} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_021}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_022} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_022}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_023} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_023}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_024} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_024}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_025} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_025}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_026} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_026}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_027} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_027}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_028} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_028}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_029} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_029}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_030} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_030}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_031} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_031}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_m_032} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_032}
                                                                     alt="Maserati"/></a>
                </div>
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
          <SimpleReactLightbox>
            <div className="h4-2">Femme</div>
            <SRLWrapper options={options}>
              <div id="gallery-with-links" className="container content content-gallery">
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_001} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_001}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_002} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_002}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_003} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_003}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_004} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_004}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_005} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_005}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_006} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_006}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_007} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_007}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_008} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_008}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_009} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_009}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_010} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_010}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_011} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_011}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_012} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_012}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_013} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_013}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_014} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_014}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_015} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_015}
                                                                     alt="Maserati"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Maserati_w_016} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_016}
                                                                     alt="Maserati"/></a>
                </div>
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
          <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Maserati') }>Nous contacter</Button></div>
        </div>

      </div>
    </section>

    <div className="separator"></div>
    <span id="yvanmonnet" style={{ position: "absolute", marginTop: "-200px" }}></span>
    <section>
      <div className="section-flex-01" style={{ margin: "0px" }}>

        <img className="img" style={{ margin: "0px", marginTop: "6%", borderRadius: "12px" }} src={Masterblock_001} alt="Gvchiani Genève"/>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3 style={{ fontSize: '7em' }}>Gvchiani Genève</h3>
          <p className="p-2 p-font">
            <strong style={{fontStyle: 'italic'}}>Le luxe de demain</strong><br/><br/>


            Vous avez choisi une montre GVCHIANI, désormais vous entrez dans un nouvel univers…<br/>

            Crée au concept de l’inconnu, chacune d’entre elles montre sa force caractériel.<br/>

            Le défi relevé par l’entreprise touche de près une technique aux résultats novateurs, comme à une création
            esthétique originale. Il en résulte un investissement sérieux en termes de temps et de réalisation. En ces
            conditions, seule une qualité irréprochable avec une grande réactivité efficace sont à même de donner
            entière satisfaction.<br/>

            Shant Ghouchian crée la Big Square titane à édition limitée, composée de 83 pièces. Une montre qui se
            définit par son nouveau design masculin moderne immédiatement reconnaissable, imposant hors norme et
            singulier, alliant l’élégance virile à la sportivité.<br/>

            Elle se démarque par sa finition, sa qualité, et sa glace en saphir originale en forme de X représentant
            l’inconnu.<br/>

            Cette fusion entre l’inconnu et la montre est pour nous l’occasion de découvrir des choses nouvelles, de
            vivre de nouvelles expériences en se confrontant à l’altérité du monde.<br/>

            Une magnifique montre conduite par le lancement d’un compte à rebours caractériel.<br/>

            Notre but est de créer des montres, homme, femme, sportive, de luxe, avec à chaque fois un design
            exceptionnel, en utilisant différents matériaux, et en créant des versions serties de diamants.<br/>

            Les montres sont équipées avec des mouvements Sellita SW500 extrêmement performants avec toujours une
            conception novatrice et créative.<br/>

            En aucun cas, Shant Ghouchian désire faire du volume, l’objectif de vente est d’environ 1000 à 1500 montres
            à série limitée par année dans tous modèles confondus, modèles pour homme et femme, en titane, en carbone,
            en or rose, en or blanc, en saphir complet, et bien d’autres nouveaux matériaux encore jamais vues.<br/>

            Au final, la pièce doit être unique.<br/>

            Cette montre masculine possède un design inédit et extrêmement fort, avec un look imposant, viril et
            provocateur.<br/>
            La Big Square représente la montre classe du futur pour ceux qui recherchent un produit nouveau et
            différent.<br/>

            Shant Ghouchian, a pour but d’entretenir le lien entre le client et la marque, en amenant l’acquéreur à être
            fier de posséder une GVCHIANI dans l’élégance et de façon sportive, avec ce sentiment d’être unique et de
            porter une montre exceptionnelle à son poignet.<br/>

            Acquérir une GVCHIANI ne s’arrête pas à s’offrir une pièce qui brille de par sa beauté et sa qualité, mais a
            pour but de vous suivre tout au long de votre vie.<br/>
            La satisfaction de nos clients est la base de notre succès.<br/>

            La plus grande réussite n’est pas de ne jamais tomber, mais de se relever à chaque chute.<br/>
          </p>

          <h5 style={{color: '#333'}}>Gvchiani & Cryptolex</h5>
          <span className="h7">Première mondiale, création d’une montre suisse de luxe mécanique dans la Blockchain.</span>

          <p className="p-2 p-font">
            Pour la première fois au monde, une montre mécanique ne va pas simplement donner l’heure mais elle va laisser une trace infalsifiable dans l’Histoire.

            La maison GVCHIANI et la société Cryptolex se sont associées dans le cadre du développement de la montre dénommée MasterBlock en hommage au Bitcoin et aux technologies du Blockchain.
            Cette montre 100 % mécanique Swiss Made donne une nouvelle dimension au 12ème Art.

            Jamais, dans l’histoire de l’horlogerie, une montre de luxe n’a été créée de A-Z dans la Blockchain, caractérisant ainsi l’ADN singulier de chaque pièce.

            Une montre, même de série, devient alors aussi unique que son propriétaire. Unique car cette montre de luxe est protégée par un certificat lié à la Blockchain émis par Cryptolex la rendant ainsi infalsifiable.
          </p>
          <Tabs defaultActiveKey="1" className="tabs-horlogeries" style={{ marginBottom: '60px' }}>
            <TabPane tab="Histoire & Concept" key="1">
              <span className="tab-pane">En 2015, les fondateurs de la maison GVCHIANI et de la société Cryptolex joignent leurs forces et créent une montre unique, tant par sa forme que par sa technologie en y intégrant les symboliques de la Blockchain et du Bitcoin : la MasterBlock.
              Avec la nouvelle ère que représente cette révolution technologique, le choix d’un design hors norme avec une forte identité visuelle devient alors une évidence. Sa forme rectangulaire et ses dimensions généreuses, font de la MasterBlock, un chronographe qui se distingue au premier coup d’œil. L’adresse unique de la montre sur la Blockchain est inscrite sur le cadran de la MasterBlock.
              Quelle que soit la position des aiguilles, l’un des quatre qrcode reste en permanence entièrement déchiffrable, ce qui permet, en outre, d’utiliser la MasterBlock comme un «paper wallet ».</span>
            </TabPane>
            <TabPane tab="Description du produit" key="2">
              <span className="tab-pane">Créativité et différence sont les caractéristiques des montres GVCHIANI.

              Au-delà de son allure atypique en forme de « Block » de titane DLC, le tracé du X reflète le code monétaire du Bitcoin (XBT).

              Le bracelet cuir, quant à lui, représente un réseau décentralisé : la « Chain ».

              Le calibre chronographe SW500 à remontage automatique de Sellita est doté d’une réserve de marche de 48 heures, d’un affichage des aiguilles heures, minutes, secondes ainsi que d’un affichage date et jour. La masse oscillante de couleur noire est spécialement personnalisée.

              Le X gravé sur la couronne, mis en valeur par une bague en titane, suscite l’attention et surtout la curiosité. Rehaussé des chiffres 3, 6, 9 et 12, le boîtier, étanche à 30m, est sublimé par la prouesse technologique qu’est une glace saphir bombée en forme de X.

              -Chaque MasterBlock aura sa propre adresse Blockchain.

              -Chaque étape de production sera gravée dans la Blockchain, permettant ainsi
              de connaître l'historique de chaque MasterBlock.

              -Chaque MasterBlock est liée à la MasterBlock précédente créant ainsi la
                chaîne.</span>
            </TabPane>
            <TabPane tab="Une montre infalsifiable!!" key="3">
            <span className="tab-pane">La MasterBlock est une édition limitée qui sera produite du numéro 0000 au numéro 2009, année du lancement du Bitcoin. Parmi elles , deux pièces exceptionnelles seront mises aux enchères :
              la 0000 dénommée « Genesis » et la 2009 dénommée « Satoshi » en hommage au créateur du Bitcoin.</span>
          </TabPane>
            <TabPane tab="A propos du créateur Shant Ghouchian" key="4">
              <span className="tab-pane">Issu d’une famille d’artisans d’art depuis des générations, Shant Ghouchian a suivi les pas de ses ancêtres et fait ses études de sertisseur aux Arts Déco de Genève. Il met, durant quatre années, ses compétences et son savoir-faire, au service de la maison Patek Philippe. Très vite, une envie d’indépendance le pousse à se mettre à son compte. C’est ainsi, qu’en 2008, passionné par son art et riche de son savoir- faire, il crée sa propre entreprise de sertissage baptisée Jewelry et Watch Settings (JWS). Reconnu pour son talent et ses compétences par les plus grands noms de la joaillerie et de l’horlogerie, le succès est au rendez-vous.</span>
            </TabPane>
            <TabPane tab="A propos de Cryptolex" key="5">
              <span className="tab-pane">Cryptolex a été fondée par Alec Avedisyan ingénieur EPFL, crypto-prophète et Vicken Bayramian, juriste spécialisé dans le négoce de matières premières et dans la Blockchain. Pour eux, la technologie Blockchain est la plus grande révolution depuis le lancement de l’internet.
              Cryptolex a vocation d’utiliser la Blockchain sous ses aspects les plus novateurs et fiables pour en tirer le meilleur dans de nombreux de domaines tels que l’horlogerie ou le négoce.
                Le slogan de Cryptolex, « Post Tenebras Cryptos » illustre bien l’ancrage à l’identité genevoise des deux créateurs.</span>
            </TabPane>
          </Tabs>


          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <div id="gallery-with-links" className="container content content-gallery">
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_001} data-attribute="SRL"><img className="img-gallery" src={Masterblock_001}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_002} data-attribute="SRL"><img className="img-gallery" src={Masterblock_002}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_003} data-attribute="SRL"><img className="img-gallery" src={Masterblock_003}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_004} data-attribute="SRL"><img className="img-gallery" src={Masterblock_004}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_005} data-attribute="SRL"><img className="img-gallery" src={Masterblock_005}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_005} data-attribute="SRL"><img className="img-gallery" src={Masterblock_005}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_006} data-attribute="SRL"><img className="img-gallery" src={Masterblock_006}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_007} data-attribute="SRL"><img className="img-gallery" src={Masterblock_007}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_008} data-attribute="SRL"><img className="img-gallery" src={Masterblock_008}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_009} data-attribute="SRL"><img className="img-gallery" src={Masterblock_009}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_010} data-attribute="SRL"><img className="img-gallery" src={Masterblock_010}
                                                                      alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Masterblock_011} data-attribute="SRL"><img className="img-gallery" src={Masterblock_011}
                                                                      alt="Yvan Monnet"/></a>
                </div>
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
          <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Gvchiani Genève') }>Nous contacter</Button></div>
        </div>

      </div>
    </section>

    <section>
      <p className="mobile-display" style={{ textAlign: "center", fontSize: "1.6em", paddingTop: "20px" }}>Toutes marques sur demandes ou voir offres sur « <Link to="/occassion">nos petites annonces horlogères</Link> »</p>
    </section>

    <div className="separator"></div>

    <h3 style={{ fontSize: "6em" }}>Accessoires</h3>

    <div className="separator"></div>

    <h4 className="h4-border">Les Bracelets :</h4>


    <span id="festina" style={{ position: "absolute", marginTop: "-200px" }}></span>
    <section>
      <div className="section-flex-01" style={{ margin: "0px" }}>

        <img className="img" style={{ margin: "0px", borderRadius: "12px" }} src={Evertest_009} alt="Bracelets Everestbands pour les montre Rolex"/>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3 style={{ fontSize: '7em' }}>Les Bracelets Everestbands pour les montre Rolex</h3>
          <p  className="p-2 p-font" style={{paddingBottom: 0, marginBottom: 0}}>
            Everest est une entreprise américaine qui produit des bracelets et des fournitures qui sont conçus
            spécifiquement pour les montre Rolex.
          </p>
          <p  className="p-2 p-font" style={{paddingTop: 0, marginTop: 0}}>
            Les bracelets sont produits en Suisse et sont de haute qualité afin de pouvoir satisfaire le client mais
            surtout pouvoir égaler la marque à la couronne.
          </p>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <div id="gallery-with-links" className="container content content-gallery">
                <div className="img-container col-image-with-link">
                  <a href={Evertest_001} data-attribute="SRL"><img className="img-gallery" src={Evertest_001}
                                                                   alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Evertest_002} data-attribute="SRL"><img className="img-gallery" src={Evertest_002}
                                                                   alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Evertest_003} data-attribute="SRL"><img className="img-gallery" src={Evertest_003}
                                                                   alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Evertest_004} data-attribute="SRL"><img className="img-gallery" src={Evertest_004}
                                                                   alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Evertest_005} data-attribute="SRL"><img className="img-gallery" src={Evertest_005}
                                                                   alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Evertest_006} data-attribute="SRL"><img className="img-gallery" src={Evertest_006}
                                                                   alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Evertest_007} data-attribute="SRL"><img className="img-gallery" src={Evertest_007}
                                                                   alt="Yvan Monnet"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Evertest_008} data-attribute="SRL"><img className="img-gallery" src={Evertest_008}
                                                                   alt="Yvan Monnet"/></a>
                </div>
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>

          <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries desktop-display-none" onClick={ () => showModalContactForm('Swisskubik') }>Nous contacter</Button></div>

          <div className="mobile-display" style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingLeft: '10%', paddingRight: '10%', marginTop: '50px'}}>
            <span className="mobile-display" style={{fontSize: '1.6em', textAlign: 'center', padding: '12px'}}>Un grand choix de bracelets pour tous les styles.</span>
            <span className="mobile-display" style={{fontSize: '1.6em', textAlign: 'center', padding: '12px'}}>Retrouvez la dernière collection dans nos boutiques.</span>
            <span className="mobile-display" style={{fontSize: '1.6em', textAlign: 'center', padding: '12px'}}>Pour toutes questions, n’hésitez pas à nous contacter.</span>

          </div>

          <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries btn-tnm mobile-display" onClick={ () => showModalContactForm('Bracelets') }>Toutes marques sur demandes</Button></div>

        </div>

      </div>
    </section>


    <div className="separator"></div>


    <h4 className="h4-border">Les Remontoirs pour montres automatiques & Rangements :</h4>


    <span id="Swisskubik" style={{ position: "absolute", marginTop: "-200px" }}></span>
    <section>
      <div className="section-flex-01" style={{ margin: "0px" }}>

        <img className="img" style={{ margin: "0px", borderRadius: "12px" }} src={swisskubik_003} alt="Swisskubik"/>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3 style={{ fontSize: '7em' }}>Swisskubik</h3>
          <p className="p-2 p-font p-font">
            De la même manière, les écrins « Swisskubik » rotatifs 100 % Swiss Made sont conçus et fabriqués manuellement avec le plus grand soin pour mettre en valeur vos montres et protéger leur mouvement. Accessoires essentiels de tout amoureux d’horlogerie, nos remontoirs sont recommandés par les plus prestigieuses maisons suisses. En permettant aux mécanismes de vos montres de n’être jamais interrompus dans leur fonctionnement, ils sont ainsi le complément naturel qui assure leur pérennité. En effet, pour garder sa précision et prolonger sa durée de vie, une montre automatique doit toujours être en mouvement. Personnalisables à l’envie, les boîtiers‑remontoirs revêtent des parures innovantes ou classiques toujours issues des matériaux les plus nobles. Ils s’accordent avec élégance et modernité à votre montre selon sa forme, sa couleur ou la matière de son bracelet. Objets de décoration à part entière, ils vous permettent de voir vos montres fonctionner. Objets de haute précision, ils leur offrent le soin qu’elles méritent lorsque vous les déposez au coffre.
          </p>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <div id="gallery-with-links" className="container content content-gallery">
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_001} data-attribute="SRL"><img className="img-gallery" src={swisskubik_001}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_002} data-attribute="SRL"><img className="img-gallery" src={swisskubik_002}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_003} data-attribute="SRL"><img className="img-gallery" src={swisskubik_003}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_004} data-attribute="SRL"><img className="img-gallery" src={swisskubik_004}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_005} data-attribute="SRL"><img className="img-gallery" src={swisskubik_005}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_006} data-attribute="SRL"><img className="img-gallery" src={swisskubik_006}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_007} data-attribute="SRL"><img className="img-gallery" src={swisskubik_007}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_008} data-attribute="SRL"><img className="img-gallery" src={swisskubik_008}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_009} data-attribute="SRL"><img className="img-gallery" src={swisskubik_009}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_010} data-attribute="SRL"><img className="img-gallery" src={swisskubik_010}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_011} data-attribute="SRL"><img className="img-gallery" src={swisskubik_011}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_012} data-attribute="SRL"><img className="img-gallery" src={swisskubik_012}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_013} data-attribute="SRL"><img className="img-gallery" src={swisskubik_013}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_014} data-attribute="SRL"><img className="img-gallery" src={swisskubik_014}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_015} data-attribute="SRL"><img className="img-gallery" src={swisskubik_015}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_016} data-attribute="SRL"><img className="img-gallery" src={swisskubik_016}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_017} data-attribute="SRL"><img className="img-gallery" src={swisskubik_017}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_018} data-attribute="SRL"><img className="img-gallery" src={swisskubik_018}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_019} data-attribute="SRL"><img className="img-gallery" src={swisskubik_019}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_020} data-attribute="SRL"><img className="img-gallery" src={swisskubik_020}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_021} data-attribute="SRL"><img className="img-gallery" src={swisskubik_021}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_022} data-attribute="SRL"><img className="img-gallery" src={swisskubik_022}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_023} data-attribute="SRL"><img className="img-gallery" src={swisskubik_023}
                                                                     alt="Swisskubik"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={swisskubik_004} data-attribute="SRL"><img className="img-gallery" src={swisskubik_004}
                                                                     alt="Swisskubik"/></a>
                </div>
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
          <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Swisskubik') }>Nous contacter</Button></div>
        </div>

      </div>
    </section>

    <div className="separator"></div>

    <span id="Scatola" style={{ position: "absolute", marginTop: "-200px" }}></span>
    <section>
      <div className="section-flex-01" style={{ margin: "0px" }}>

        <img className="img" style={{ margin: "0px", borderRadius: "12px" }} src={Remontroirs_029} alt="Scatola del Tempo"/>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3 style={{ fontSize: '7em' }}>Scatola del Tempo</h3>
          <p className="p-2 p-font">
            Depuis 1989, au début de sa nouvelle activité, Sandro Colarieti décide de faire évoluer les boîtiers qu'il crée pour stocker ses montres en produisant une petite série de remontoirs pour lui et ses amis. Son objectif: simuler les mouvements naturels du poignet pour maintenir ses montres et les garder à l'heure. Grand collectionneur de montres Patek Philippe, il présente son invention à la célèbre maison horlogère en 1990, qui décide, après un an de tests, de commander 500 remontoirs. Grâce à cette collaboration, Scatola s'est rapidement fait connaître des collectionneurs du monde entier. Le bobineur est né et approuvé! L'engouement des collectionneurs ne cesse de croître et leurs attentes sont de plus en plus exigeantes en termes de personnalisation et de capacité. Scatola del Tempo aime les défis! La gamme est élargie avec des articles sur mesure capables de contenir jusqu'à des centaines de montres.
          </p>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <div id="gallery-with-links" className="container content content-gallery">
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_001} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_001}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_002} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_002}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_003} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_003}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_004} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_004}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_005} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_005}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_006} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_006}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_007} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_007}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_008} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_008}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_009} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_009}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_010} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_010}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_011} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_011}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_012} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_012}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_013} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_013}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_014} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_014}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_015} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_015}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_016} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_016}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_017} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_017}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_018} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_018}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_019} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_019}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_020} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_020}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_021} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_021}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_022} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_022}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_023} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_023}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_024} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_024}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_025} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_025}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_026} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_026}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_027} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_027}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
                <div className="img-container col-image-with-link">
                  <a href={Remontroirs_028} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_028}
                                                                      alt="Scatola del Tempo"/></a>
                </div>
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
          <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Scatola del Tempo') }>Nous contacter</Button></div>
        </div>

      </div>
    </section>

    <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
      visible={ showModalContact }
      onOk={ handleContactFormOk }
      onCancel={ handleContactFormCancel }
      okButtonProps={{ style: { display: 'none' } }}
    >

      <ContactForm page={dataContact} />

    </Modal>

  </Layout>
    </>
)
}

export default Horlogeries
